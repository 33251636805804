<template>
    <div class="row">
        <div class="col-md-5">
            <div class="card card-warning">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.equipos') }}</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-7">
                            <label for="nombre_completo">{{ $t('general.nombre') }}</label>
                            <input type="text" class="form-control nombre_completo dato" id="nombre_completo" v-model='nombre' placeholder="Nombre">
                        </div>
                        <div class="col-md-1 d-flex align-items-end">
                            <button class="btn btn-primary" @click="agregarEquipo(nombre)">{{ $t('general.anadir') }}</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-sm table-striped">
                                <tbody>
                                    <tr v-for="equipo in equipos" :key="equipo.id">                                        
                                        <td width="80%" v-if="!editMode[equipo.id]">{{ equipo.nombre_equipo }}</td>
                                        <td v-else @keydown.esc="cancelEditMode(equipo.id)">
                                            <input type="text" v-model="equipo.nombre_equipo" class="form-control" />
                                        </td>
                                        <td v-if="!editMode[equipo.id]"><button class="btn btn-light btn-sm" title="Editar"   @click="toggleEditMode(equipo.id)"><i class="fas fa-edit" ></i></button></td>
                                        <td v-else><button @click="cancelEditMode(equipo.id)" class="btn btn-light btn-sm"  title="Cancelar" ><i class="fas fa-ban"></i></button></td>
                                        <td v-if="!editMode[equipo.id]"><button class="btn btn-light btn-sm" @click="eliminarEquipo(equipo.id)"  title="Eliminar"><i class="fas fa-trash-alt"></i></button></td>
                                        <td v-else><button @click="actualizarEquipo(equipo.id, equipo.nombre_equipo)" title="Guardar" class="btn btn-light btn-sm"><i class="fas fa-save"  ></i></button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    data() {
        return {
            nombre:'',
            equipos: [],
            editMode: {
            },
        }
    },
    watch: {
    },
    methods: {
        async toggleEditMode(equipoId) {           
            this.editMode[equipoId] = true;        
        },
        async cancelEditMode(equipoId) {           
            delete this.editMode[equipoId];  
            this.cargarDatos();    
        },
        async cargarDatos() {
        const api = new PwgsApi;
        const datos = await api.get('tipos-equipo');
        this.equipos = datos.datos;
        },
        async eliminarEquipo(id) {
            const api = new PwgsApi;
            await api.delete('tipos-equipo/' + id);
            this.cargarDatos();
        },
        async actualizarEquipo(id, nombre_equipo) {
            const api = new PwgsApi;
            let body = {nombre_equipo: nombre_equipo};
            await api.put('tipos-equipo/' + id, body);
            delete this.editMode[id];
            this.cargarDatos();
        },
        async agregarEquipo(nombre_equipo) {
            const api = new PwgsApi;
            let body = {nombre_equipo: nombre_equipo};
            await api.post('tipos-equipo/', body);
            this.cargarDatos();
        },
    },
    mounted(){
        this.cargarDatos()
    }
}

</script>